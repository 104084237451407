import { bind, shareLatest } from '@react-rxjs/core';
import { Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Config } from '../../../../config/config';
import { CURRENCYPREFIX } from '../../../../shared/formatters';
import { ProductValue } from '../../../../shared/ProductValue';
import { getFetchClearing$ } from '../../../../shared/services/fetchService';

export interface BlockEntryFormState {
  buySide: BlockSide;
  sellSide: BlockSide;
  negotiatedTime?: Date;
  price?: number;
  quantity?: number;
  senderSubId?: string;
}

export interface BlockSide {
  accountLabel?: string;
  clOrdId?: string;
  customerAccountRef?: string;
  origin?: string;
  cti?: string;
  clearingMemberId?: string;
  exchangeMemberId?: string;
}

interface BlockSidePayload {
  account_label?: string;
  cl_ord_id?: string;
  customer_account_ref?: string;
  sender_sub_id?: string;
  account_type?: string;
  cust_order_capacity?: string;
  clearing_member_id?: string;
  exchange_member_id?: string;
}

interface BlockTradePayload {
  contract_symbol: string;
  price: number;
  quantity: number;
  negotiated_time: number;
  sell_side: BlockSidePayload;
  buy_side: BlockSidePayload;
  correlation: string;
}

export const errorMapping = {
  1: 'Unknown Symbol',
  2: 'Invalid Quantity',
  3: 'Invalid Price',
  4: 'Invalid Negotiated Block Trade Time',
  5: 'Instrument Closed',
  6: 'Trading is Halted',
  7: 'Invalid Buyer Account ID',
  8: 'Invalid Seller Account ID',
  9: 'Buyer Account Not Enabled for Futures',
  10: 'Seller Account Not Enabled for Futures',
  11: 'Buyer Account Not Enabled for Spot',
  12: 'Seller Account Not Enabled for Spot',
  13: 'Duplicate Request',
  14: 'Buyer Missing Regulatory Field',
  15: 'Seller Missing Regulatory Field',
  16: 'Buyer Insufficient Purchasing Power',
  17: 'Seller Insufficient Purchasing Power',
  18: 'Rejected',
} as const;

type BlockTradeErrorCode = keyof typeof errorMapping;
export type BlockTradeErrorCodeMessage = typeof errorMapping[BlockTradeErrorCode];

export interface BlockTradeEntryResponse {
  error?: {
    request_id: string;
    error_code: BlockTradeErrorCodeMessage;
    state: 'rejected'
  }
  restError?: boolean;
  type?: string;
  state?: 'accepted' | 'cleared';
  request_id?: string;
  correlation: string;
}
export interface BlockTradeRequest {
  product: ProductValue;
  entry: BlockEntryFormState;
  correlation: string;
}

const convertBlockSide = (entry: BlockSide, senderSubId: string | undefined) => ({
  account_label: entry.accountLabel,
  cl_ord_id: entry.clOrdId,
  customer_account_ref: entry.customerAccountRef,
  sender_sub_id: senderSubId,
  account_type: entry.origin,
  cust_order_capacity: entry.cti,
  clearing_member_id: entry.clearingMemberId,
  exchange_member_id: entry.exchangeMemberId,
});

const convertRequestToPayload = (
  { product, entry, correlation }: BlockTradeRequest,
): BlockTradePayload => ({
  buy_side: convertBlockSide(entry.buySide, entry.senderSubId),
  sell_side: convertBlockSide(entry.sellSide, entry.senderSubId),
  contract_symbol: `${(Config.APP_ENV !== 'PRODUCTION' && product.type === 'SPOT') ? CURRENCYPREFIX : ''}${
    product.symbol
  }`,
  negotiated_time: entry.negotiatedTime ? entry.negotiatedTime.getTime() : 0,
  quantity: entry.quantity!,
  price: entry.price!,
  correlation,
});

export const blockEntry$ = new Subject<BlockTradeRequest>();

export const submitBlockEntry = (blockEntry: BlockTradeRequest) => {
  blockEntry$.next(blockEntry);
};

const submitBlockTrade$ = (request: BlockTradeRequest) => getFetchClearing$({
  restCallName: 'submit_block_trade',
  body: convertRequestToPayload(request),
}).pipe(
  map((fetchResponse) => {
    const response = fetchResponse.error && !fetchResponse.response.error ? {
      restError: true,
    } : fetchResponse.response;
    return ({
      ...response,
      correlation: request.correlation,
    } as BlockTradeEntryResponse);
  }),
);

export const blockTradeResponse$ = blockEntry$.pipe(
  switchMap((entry) => submitBlockTrade$(entry)),
  shareLatest(),
);

blockTradeResponse$.subscribe();

export const [useBlockTradeResponse] = bind(blockTradeResponse$, null);
