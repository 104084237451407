import { createListener } from '@react-rxjs/utils';
import { combineLatest, defer, Observable } from 'rxjs';
import {
  filter, map, scan, startWith, switchMap, tap,
} from 'rxjs/operators';
import { shareLatest } from '@react-rxjs/core';
import {
  send,
  subscribeToMessageStream$,
} from '../../shared/websocket/transport';
import { Security } from '../../shared/services/getSecurities$';
import { securityListState$ } from '../../shared/services/securitiesService';
import { FUT, MLEG } from '../orderEntry/services/trade/sendEntry';
import { requestBlockTradeData } from '../orderGridsTabPanel/services/blockTrades/blockTradesService';
import {
  createCancelQuoteRequest,
  transformFormStateToRequest,
  transformFormStateToTradeRequest,
  transformResponseMapWithOrdering,
} from './helpers';
import {
  OTCStreamQuoteResponse,
  RequestQuoteFormState,
  Status,
  STREAMING_QUOTE_RESPONSE,
  TradeFormState,
  TradeResponse,
  TRADE_RESPONSE,
} from './types';

const [submitRequestQuote$, submitRequestQuote] = createListener<
RequestQuoteFormState
>();
const [submitCancelQuote$, submitCancelQuote] = createListener<{
  counterpartyRequestId: string;
  quoteResponseId: {
    version: number;
    id: string;
  };
}>();
const [submitTradeRequest$, submitTradeRequest] = createListener<
TradeFormState
>();

const [orderedIds$, setOrderedIds] = createListener<Set<string>>();

export {
  submitRequestQuote,
  submitCancelQuote,
  submitTradeRequest,
  orderedIds$,
  setOrderedIds,
};

const quoteResponse$ = defer(
  () => (subscribeToMessageStream$(
    STREAMING_QUOTE_RESPONSE,
  ) as unknown) as Observable<OTCStreamQuoteResponse>,
);

const sendRequestQuote$ = (formState: RequestQuoteFormState) => defer(() => {
  const otcRequest = transformFormStateToRequest(formState);
  send(otcRequest);
  return quoteResponse$.pipe(
    filter((msg) => msg.correlation === otcRequest.correlation),
  );
});

export const filteredQuoteResponse$ = submitRequestQuote$.pipe(
  switchMap((formState) => sendRequestQuote$(formState)),
);

export const requestQuote$ = combineLatest([
  quoteResponse$,
  orderedIds$.pipe(startWith(null)),
]).pipe(
  scan(
    (acc, [currResponse, orderedIds]) => {
      const { counterpartyRequestId } = currResponse.quoteRequest;
      switch (currResponse.status) {
        case Status.CLOSED:
        case Status.FILLED:
          acc.response.delete(counterpartyRequestId);

          return {
            ...acc,
            orderedIds: orderedIds
            // ensures orderedIds are up to date
              ? new Set(
                [...orderedIds].filter((id) => [...acc.response.keys()].includes(id)),
              )
              : null,
          };
        case Status.OPEN:
          acc.response.set(counterpartyRequestId, currResponse);
          return {
            ...acc,
            orderedIds: orderedIds
            // ensures orderedIds are up to date
              ? new Set(
                [...orderedIds, ...acc.response.keys()].filter(
                  (id) => [...acc.response.keys()].includes(id),
                ),
              )
              : null,
          };
        default:
          return acc;
      }
    },
    {
      response: new Map<string, OTCStreamQuoteResponse>(),
      orderedIds: null as Set<string> | null,
    },
  ),
  map(({ response, orderedIds }) => transformResponseMapWithOrdering(response, orderedIds)),
  startWith(null),
);

export const cancelQuote$ = submitCancelQuote$.pipe(
  switchMap(({ counterpartyRequestId, quoteResponseId }) => defer(() => {
    const cancelRequest = createCancelQuoteRequest(
      counterpartyRequestId,
      quoteResponseId,
    );
    send(cancelRequest);
  })),
);

export const otcTradeResponse$ = (subscribeToMessageStream$(
  TRADE_RESPONSE,
).pipe(
  tap((tradeResponse: any) => requestBlockTradeData(tradeResponse.blockTradeId)),
  shareLatest(),
) as unknown) as Observable<TradeResponse>;

const sendTradeRequest$ = (
  formState: TradeFormState,
  { roundLot }: Security,
) => {
  const tradeRequest = transformFormStateToTradeRequest(formState, roundLot);
  send(tradeRequest);
  return otcTradeResponse$;
};

const spotsSecurities$ = securityListState$.pipe(
  // eslint-disable-next-line max-len
  map(({ securities }) => securities.filter(({ securityType }) => securityType !== FUT && securityType !== MLEG)),
);

export const submitTrade$ = submitTradeRequest$.pipe(
  switchMap((formState) => spotsSecurities$.pipe(
    map((spots) => {
      const found = spots.find(
        (security) => security.symbol === formState.symbol,
      );
      if (found) {
        return { security: found, formState };
      }
      throw new Error(
        'Selected product is not a supported security. Please try again with another product.',
      );
    }),
  )),
  switchMap(({ security, formState }) => sendTradeRequest$(formState, security)),
  startWith(null),
);
