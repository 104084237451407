import { defer, Observable } from 'rxjs';
import { correlationId } from '../helperFunctions/correlationId';
import { send, subscribeToMessageStream$ } from '../websocket/transport';
import { MLEG } from '../../components/orderEntry/services/trade/sendEntry';

export interface Security {
  currency: string;
  symbol: string;
  securityDesc: string;
  minTradeVol: number;
  maxTradeVol: number;
  roundLot: number;
  minPriceIncrement: number;
  product: string;
  securityType: string;
  productCode: string;
  securityGroup: string;
  contractMultiplier: number | null;
  maturityDate: number;
  floor?: number;
  cap?: number;
  expiryTime?: number;
}

export interface SecuritiesResponse {
  securities: Security[];
  type: 'SecuritiesResponse';
  correlation: string;
}

export const getSecurities$ = defer(() => {
  send({
    correlation: correlationId(),
    type: 'SecurityList',
  });
  return subscribeToMessageStream$('SecuritiesResponse') as Observable<
  SecuritiesResponse
  >;
});

export const isNotFutureSpread = (security: Security) => security.securityType !== MLEG;
