import { createListener } from '@react-rxjs/utils';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ProductValue } from '../../../../shared/ProductValue';
import { selectedMarket$ } from '../../../../shared/services/selectedMarketService';
import { Nullable } from '../../../../shared/types';
import { FUT, MLEG } from '../../../orderEntry/services/trade/sendEntry';
import { Security } from '../../../../shared/services/getSecurities$';
import {
  allSecuritiesState$,
  futuresListState$,
  selectedContract$,
  selectedProduct$,
} from './productSelectorState';

export const [
  contractFilterPredicate$,
  setContractFilterPredicate,
] = createListener<string>();

export const selectedProductContract$ = combineLatest([
  selectedProduct$.pipe(startWith(null)),
  selectedContract$.pipe(startWith(null)),
  selectedMarket$,
  futuresListState$,
]).pipe(
  map(([selectedProduct, selectedContract, subscribedMarket, futures]) => {
    const state = { selectedContract, selectedProduct };
    if (selectedProduct && selectedProduct.type === FUT) {
      return state;
    }
    if (subscribedMarket) {
      if (subscribedMarket.type !== FUT) {
        state.selectedProduct = subscribedMarket;
      } else {
        const selectedFutureProduct = futures.find(
          (future) => future.symbol === subscribedMarket.productCode,
        );
        if (selectedFutureProduct) {
          state.selectedProduct = selectedFutureProduct;
        }
        state.selectedContract = subscribedMarket;
      }
    }
    return state;
  }),
);

export const createContractsListState$ = (
  _allSecuritiesState$: Observable<Security[]>,
  _selectedProductContract$: Observable<{
    selectedContract: Nullable<ProductValue>;
    selectedProduct: Nullable<ProductValue>;
  }>,
  _contractFilterPredicate$: Observable<string>,
) => combineLatest([
  _allSecuritiesState$,
  _selectedProductContract$,
  _contractFilterPredicate$.pipe(startWith('')),
]).pipe(
  map(
    ([securities, { selectedProduct }, filterPredicate]) => securities
      .filter(
        (security) => security.securityType === FUT || security.securityType === MLEG,
      )
      .filter(
        (contract) => selectedProduct
              && contract.productCode === selectedProduct.symbol
              && contract.symbol
                .toLowerCase()
                .includes(filterPredicate.toLowerCase()),
      )
      .map((security) => ({
        type: FUT,
        productCode: security.productCode,
        symbol: security.symbol,
        value: security.symbol,
        minTradeVol: security.minTradeVol,
        maxTradeVol: security.maxTradeVol,
        roundLot: security.roundLot,
        minPriceIncrement: security.minPriceIncrement,
        currency: security.currency,
        contractMultiplier: security.contractMultiplier,
      })) as ProductValue[],
  ),
);

export const contractsListState$ = createContractsListState$(
  allSecuritiesState$,
  selectedProductContract$,
  contractFilterPredicate$,
);
